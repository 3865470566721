<script>
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { makeResizable } from '@/helpers/makeResizable';
import { defineAsyncComponent } from 'vue';
import SuportFunctionsComponent from './suportFunctions.vue';
import { useOrderBy } from './orderBy';
import DocumentsVisualizationModal from './documentsVisualizationModal.vue';

const CustomInputAsync = defineAsyncComponent(() => import('../custom_input/index'));

export default {
  name: 'detailTable',
  props: {
    table: {
      type: Object,
      required: true
    },
    formType: {
      type: String,
      requerid: true,
    },
    mainTableFormModifier: {
      type: Object,
      required: true,
    },
    programSchema: {
      type: Object,
      required: false,
    },
    isBtnLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    tipoAplicacao: {
      type: Number,
      required: false,
      default: null
    }
  },
  setup(props) {
    const primaryKeyName = props.table.schema.sis_campo_tabela.find((column) => column.chave_primaria).nome;
    const parentPrimaryKeyName = props.mainTableFormModifier.table_schema.sis_campo_tabela.find((column) => column.chave_primaria).nome;
    const parentTableName = ref(props.mainTableFormModifier.table_schema.nome_tabela);
    const formType_ = ref(
      props.table.schema.id_tabela === '1085aac4-3b86-495c-bbc3-9ae41fca43a9' && props.mainTableFormModifier.initialValues.possui_respostas
        ? 'show'
        : props.formType
    )
    

    const suportFunctions =
      // Conta Financeira -> usuarios
      props.mainTableFormModifier.table_schema.id_tabela == 'e8f03ced-d35e-bbdd-c318-4c0cabdccebe' && props.table.schema.id_tabela == '7bf45d63-5490-fbe1-78b7-dc6c73ee41ac' ||
      // Conta Financeira -> Integrações
      props.mainTableFormModifier.table_schema.id_tabela == 'e8f03ced-d35e-bbdd-c318-4c0cabdccebe' && props.table.schema.id_tabela == 'd7cf7354-1652-d30f-25c4-323db6a3ce32' ||
      // Categoria/Departamento -> sis_usuario
      props.mainTableFormModifier.table_schema.id_tabela == '4b0fe29c-58fa-4b15-844c-fe2762a18020' && props.table.schema.id_tabela == 'dcfbcad8-b5d3-41ed-91a0-3a45502f6676' ||
      // titulo_financeiro -> documentos || eventos 
      (props.mainTableFormModifier.table_schema.id_tabela == '94bcbd6c-6ce6-d44f-24e3-414ac558b1b9' &&
        (props.table.schema.id_tabela == 'a70db5f2-2187-cacf-3545-f3b8a9000888' || props.table.schema.id_tabela == '677381ef-3233-be8a-eaab-227f0396a396')
      ) ||
      // sis_painel -> sis_consulta_painel
      props.mainTableFormModifier.table_schema.id_tabela == '02ae2a7f-0b43-4ffc-af34-22e3a152be27' && props.table.schema.id_tabela == '3172d174-b55e-4993-b863-48d5e92f6663' ||
      // solicitação de compra -> item_cotacao || sis_documentos
      props.mainTableFormModifier.table_schema.id_tabela == 'b5cdb60a-866e-f633-133a-31d2c1f0080d' && (
        ['805e7a21-45ed-eba2-014e-a89550cef358' ,'3c4a4151-2aa0-46d4-886c-d06c24f1ee5b'].includes(props.table.schema.id_tabela)
      ) ||
      // cotação de compra -> item_cotacao || sis_documentos
      props.mainTableFormModifier.table_schema.id_tabela == '3e27db5c-cfa8-4435-aac7-267cb6079421' && (
        ['3c4a4151-2aa0-46d4-886c-d06c24f1ee5b','e5ebf9f1-13dc-477e-8c05-60186dcce22f'].includes(props.table.schema.id_tabela)
      ) ||
      // Pedido de compra -> sis_documentos
      props.mainTableFormModifier.table_schema.id_tabela == '10bb42f1-6e78-46d2-9935-00243f32f23e' && (
        ['3c4a4151-2aa0-46d4-886c-d06c24f1ee5b'].includes(props.table.schema.id_tabela)
      ) ||
      // sis_formulario -> sis_formulario_resposta
      props.mainTableFormModifier.table_schema.id_tabela == '8e82a5c0-c9a4-4d16-a6a3-9559bcc359b7' && props.table.schema.id_tabela == '4c63deb6-b393-4f2f-b489-a376c628a0d1'
      ||
      // movimentacao -> contas a pagar documentos 
      props.mainTableFormModifier.table_schema.id_tabela == '0c31e745-1943-4227-827d-ff9fb800bcee' && props.table.schema.id_tabela == 'a70db5f2-2187-cacf-3545-f3b8a9000888'
      ||
      props.mainTableFormModifier.table_schema.id_tabela == '45ec557a-5ba3-0205-bdbc-c1d61b30c764' && props.table.schema.id_tabela == '2ae81b73-e0fb-496a-8ea9-633c24e82f35'

    const notSuportSelect = props.mainTableFormModifier.table_schema.id_tabela == '4b0fe29c-58fa-4b15-844c-fe2762a18020' && props.table.schema.id_tabela == 'dcfbcad8-b5d3-41ed-91a0-3a45502f6676' 
    ||
    props.mainTableFormModifier.table_schema.id_tabela == 'e8f03ced-d35e-bbdd-c318-4c0cabdccebe' && props.table.schema.id_tabela == '7bf45d63-5490-fbe1-78b7-dc6c73ee41ac'

    const suportOnlyEdit = props.mainTableFormModifier.table_schema.id_tabela == '10bb42f1-6e78-46d2-9935-00243f32f23e' && props.table.schema.id_tabela == '72f303d8-d543-4761-97e3-465cf441decb'

    const rowIds = ref([])
    const selectedRowIds = ref([])
    const showFileModal = ref(false)
    const showConsultaDadosModal = ref(false)
    const lastScrollTopUpdate = ref(100)
    const pixelLimit = ref(120)
    const detailTable = ref(null)
    const randomTableId = Math.random().toString(10).substring(2, 5) + Date.now().toString()
    const containerDetailTable = ref(null)
    const filesToShow = ref([])

    const {
      order,
      orderBy,
      orderConfig,
      detail_table,
    } = useOrderBy(props.table)

    onMounted(() => {
      let targetTable = document.getElementById(randomTableId)
      if (!targetTable) return;
      const resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          if (entry.contentRect && entry.contentRect.width > 0) {
            makeResizable(detailTable)
            resizeObserver.unobserve(targetTable);
          }
        }
      })
      resizeObserver.observe(targetTable);

      containerDetailTable.value.addEventListener('scroll', handleWindowScroll)
    });

    onUnmounted(() => {
      containerDetailTable.value?.removeEventListener('scroll', handleWindowScroll)
    });

    watch (() => detail_table.shouldResetScroll, (newValue) => {
      if(newValue && containerDetailTable.value){
        containerDetailTable.value.scrollTop = 0
        pixelLimit.value = 120
        lastScrollTopUpdate.value = 100
        detail_table.shouldResetScroll = false
      }
    })

    function handleWindowScroll(event){
      if((event.target.scrollTop - lastScrollTopUpdate.value) > pixelLimit.value){
        if(detail_table.forms.filter(f => f.active).length < detail_table.total_registers && detail_table.isLoaded){
          pixelLimit.value = pixelLimit.value*1.2
          lastScrollTopUpdate.value = event.target.scrollTop
          renderMore(detail_table)
        }
      }
    }

    function renderMore(detail_table){
      props.mainTableFormModifier.setActivateDetailTable(detail_table.forms.filter(f => f.active).length, detail_table)
    }

    // funções para a tabela detalhe se suportFunctions == true
    // ordenação inicial
    onMounted(() => {
      if (suportFunctions) {
        order()
        rowIds.value = props.table.forms.map((form, index) => index)
      }
    })
    watch(() => props.table.isLoaded, (newValue) => {
      if (newValue) {
        order()
        if (suportFunctions) {
          rowIds.value = props.table.forms.map((form, index) => index)
        }
      }
    })
    watch(() => props.isBtnLoading, (newValue) => {
      if (newValue && suportFunctions) {
        selectedRowIds.value = []
      }
    })

    const onChangeCheckbox = (index, event) => {
      if (event.target.checked) {
        selectedRowIds.value.push(index);
      } else {
        selectedRowIds.value.splice(selectedRowIds.value.indexOf(index), 1);
      }
    }

    const selectOrUnselectAll = () => {
      if (rowIds.value.length == 0) return;
      if ((rowIds.value.length !== selectedRowIds.value.length) || selectedRowIds.value.length == 0) {
        selectedRowIds.value = rowIds.value.map((id) => id);
      } else {
        selectedRowIds.value = [];
      }
    }

    const setFilesToShow = (files) => {
      filesToShow.value = files
    }

    return {
      setFilesToShow,
      containerDetailTable,
      orderBy,
      orderConfig,
      parentPrimaryKeyName,
      parentTableName,
      detail_table,
      detailTable,
      randomTableId,
      suportFunctions,
      selectedRowIds,
      selectOrUnselectAll,
      showFileModal,
      primaryKeyName,
      showConsultaDadosModal,
      onChangeCheckbox,
      formType_,
      notSuportSelect,
      filesToShow,
      suportOnlyEdit
    }
  },
  components: {
    CustomInputAsync,
    SuportFunctionsComponent,
    DocumentsVisualizationModal
  },
};
</script>

<template>
  <div>
    <SuportFunctionsComponent v-if="detail_table.isLoaded && suportFunctions"
      :nome_tabela="detail_table.schema.nome_tabela"
      :id_tabela="detail_table.schema.id_tabela"
      :programSchema="this.$props.programSchema"
      :selectedRowIds="selectedRowIds"
      :table="detail_table"
      :primaryKeyName="primaryKeyName"
      :parentPrimaryKeyName="parentPrimaryKeyName"
      :parentTableName="parentTableName"
      :parent_id_tabela="this.$props.mainTableFormModifier.table_schema.id_tabela"
      :setFiles="(files) => setFilesToShow(files)"
      :setShowFileModal="(value) => showFileModal = value"
      :updateSelectedRowIds="(value) => selectedRowIds = value"
      :mainTableFormModifier = this.$props.mainTableFormModifier
      :tipoAplicacao="this.$props.tipoAplicacao"
    />
    
    <div v-if="table.schema.id_tabela !== '4c63deb6-b393-4f2f-b489-a376c628a0d1'" class="w-100" ref="containerDetailTable"
      style="overflow-x: scroll;overflow-y: scroll;max-height:600px;">
      <table :id="randomTableId" class="table table-bordered table-nowrap mb-0" ref="detailTable">
        <thead>
          <tr>
            <template v-if="formType_ !== 'show' || suportFunctions">
              <th v-if="suportFunctions && !notSuportSelect && (detail_table.permite_inclusao || detail_table.permite_alteracao)" scope="col"
                :style="`width: ${detail_table.permite_exclusao && detail_table.permite_alteracao ? 75 : 35}px;`"
                class="m-0 p-0 no-resizable"></th>
              <th scope="col" class="m-0 p-0 no-resizable" :style="`width: ${detail_table.permite_exclusao && detail_table.permite_alteracao ? 75 : 35}px;`">
                <div v-if="!detail_table.isLoaded" class="d-flex justify-content-center">
                  <b-spinner variant="success"></b-spinner>
                </div>
                <div v-else-if="suportFunctions && !notSuportSelect">
                  <button @click="selectOrUnselectAll" type="button"
                    class="w-100 text-primary btn btn-icon fs-22 rounded-0 h-100"><i
                      class="ri-check-double-fill"></i></button>
                </div>
              </th>
            </template>
            <template v-if="suportOnlyEdit">
              <th class="m-0 p-0 fix-resize-w" style="width: 35px;"></th>
            </template>
            <template v-for="column in detail_table.schema.sis_campo_tabela" :key="column.id_campo_tabela">
              <th scope="col" :class="(this.$props.formType == 'show' ? 'p-2' : 'p-2') + ' position-relative'"
                v-if="column.exibe_navegador">
                <div class="d-flex gap-1 align-items-center">
                  <span>{{ column.titulo }}</span>
                  <span class="text-danger" v-if="column.requerido && this.$props.formType !== 'show'">*</span>
                  <button @click="orderBy($event, column)" tabindex="-1" type="button"
                    :class="['ms-2', 'gridjs-sort', `gridjs-sort-${orderConfig.columns.find(c => c.name === column.nome)?.order || 'neutral'}`]"></button>
                </div>
              </th>
            </template>
          </tr>
        </thead>
        <tbody>
          <template v-if="formType_ !== 'show'">
            <tr>
              <td colspan="100%" class="bg-light m-0 p-0 no-resizable fix-resize-w" style="width: 35px;">
                <button v-if="detail_table.permite_inclusao && detail_table.isLoaded" :style="`width: ${detail_table.permite_exclusao && detail_table.permite_alteracao ? 75 : 35}px;`"
                  @click="this.$emit('addRow', detail_table.schema.id_tabela)" type="button"
                  class="btn btn-icon btn-success table-detail-add" title="Adicionar">
                  <i class="ri-add-line fs-22"></i>
                </button>
              </td>
            </tr>
          </template>
          <tr v-for="form, index in detail_table.forms.filter(form => form.active)" :key="form.id"
            :class="[selectedRowIds.includes(index) ? 'gridjs-tr-selected' : '', 'detail_table_row']">
            <template v-if="formType_ !== 'show' || suportFunctions">
              <td v-if="!suportFunctions && !detail_table.permite_exclusao && !detail_table.permite_inclusao"
                class="m-0 p-0 fix-resize-w" style="width: 35px;"></td>
              <td class="m-0 p-0 fix-resize-w" v-if="detail_table.permite_exclusao || detail_table.permite_alteracao">
                <button v-if="detail_table.permite_exclusao && !form.deleted"
                  @click="() => form.setDeleted(true)"
                  type="button" class="btn btn-icon btn-danger table-detail-add" title="Remover">
                  <i class="ri-close-line fs-22"></i>
                </button>
                <button v-if="detail_table.permite_exclusao && form.deleted"
                  @click="() => form.setDeleted(false)"
                  type="button" class="btn btn-icon btn-danger table-detail-add" title="Desfazer">
                  <i class="ri-refresh-line fs-22"></i>
                </button>
                <button v-if="detail_table.permite_alteracao"
                  :disabled="(formType_ === 'edition' && form.mode === 'edition') || form.deleted"
                  @click="() => {form.setMode('edition', form.getOriginalValues()); form.setHasChanged(true)}"
                  type="button" :class="['border-start btn btn-icon table-detail-add', formType_ === 'edition' && form.mode === 'edition' ? 'btn-light' : 'btn-warning']" title="Editar">
                  <i class="mdi mdi-pencil fs-22"></i>
                </button>
              </td>
              <td v-if="suportFunctions && !notSuportSelect" class="gridjs-td p-0">
                <input :checked="selectedRowIds.includes(index)" @change="onChangeCheckbox(index, $event)"
                  type="checkbox"
                  :class="['gridjs-checkbox mx-auto my-auto grid_detail_checkbox', detail_table.schema.nome_tabela]">
              </td>
            </template>
            <td :class="['m-0 p-0 fix-td-height', !column.exibe_navegador ? 'd-none' : '', form.deleted ? 'opacity-25 pe-none' : '']"
              v-for="column in detail_table.schema.sis_campo_tabela" :key="form.id + column.id_campo_tabela">
              <CustomInputAsync 
                :detailTable="true" 
                :detailTableIdName="detail_table.detailTableIdName"
                :primaryKeyValue="detail_table.mainTablePrimaryKey" 
                :formState="form"
                :dataAlreadyExists="form.dataAlreadyExists" 
                :formType="formType_"
                :columnSchema="form.getColumnByName(column.nome)" 
                :showLabel="false"
                :input-class="'border-dashed rounded-0 ' + (column.exibe_navegador && !form.deleted ? 'needs-validation needs_validation_detail_table_' + form.table_schema.id_tabela : 'not_validate')" />
            </td>
          </tr>
          <!-- <template v-if="formType_ !== 'show'">
            <tr>
              <td colspan="100%" class="m-0 p-0 no-resizable fix-resize-w" style="width: 35px;">
                <button v-if="detail_table.permite_inclusao && detail_table.isLoaded"
                  @click="this.$emit('addRow', detail_table.schema.id_tabela)" type="button"
                  class="btn btn-icon btn-success table-detail-add" title="Adicionar">
                  <i class="ri-add-line fs-22"></i>
                </button>
              </td>
            </tr>
          </template> -->
        </tbody>
      </table>
    </div>
    <div class="flex-column d-flex align-items-center">
      <span v-if="detail_table.forms.length" class="text-muted small ms-auto">Mostrando <span class="fw-semibold">1</span> até <span class="fw-semibold">{{ detail_table.forms.filter(f => f.active).length }}</span> de <span class="fw-semibold">{{detail_table.total_registers}}</span></span>
    </div>

    <DocumentsVisualizationModal
      :files="filesToShow" 
      :original_documents="detail_table.forms.map(f => f.initialValues)" 
      :showFileModal="showFileModal"
      :setShowFileModal="(value) => showFileModal = value"
      :onClose="() => setFilesToShow([])"
    />

    
  </div>
</template>